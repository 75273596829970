/*
 * @Author: your name
 * @Date: 2020-01-13 09:03:45
 * @LastEditTime: 2022-01-13 11:29:48
 * @LastEditors: qyy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\join-us\jobDetails\JobDetails.js
 */
import React from 'react';
//import './index.css';
import './job-details.less';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Axios from 'axios';


class JobDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            careers: {},
        }
    }

    componentDidMount() {
        if (typeof (this.props.match.params) != "undefined") {
            console.log(this.props.match.params.id);
            var id = this.props.match.params.id;
        }
        console.log(id);

        Axios.get(global.config.serverUrl + '/JobsInfo/LoadInfo/' + id)
            .then((careers) => {
                console.log(careers.data);
                this.setState({ careers: careers.data })
            })
            .catch((error) => {
                console.log(error);
            });

    }



    render() {
        return (
            <div>
                <Head />
                <div className="job-details">
                    <div className="bg"></div>
                    <div className="job-title">{this.state.careers.JobsName}</div>
                    <div className="job-company">杭州新中大企业管理技术有限公司</div>
                    <div className="job-address">{this.state.careers.JobsAddress} / {this.state.careers.JobsNum}人</div>
                    <div className="interval-157" />
                    {/*岗位描述模块开始 */}
                    <div className="job-component">
                        <div className="job-icon"></div>
                        <div className="job-component-main">
                            <div className="job-component-title">岗位描述</div>
                            <div className="job-component-text" dangerouslySetInnerHTML={{
                                __html:
                                    this.state.careers.JobsIntroductionHtml && this.state.careers.JobsIntroductionHtml.replaceAll('http://47.96.72.83:8086', global.config.serverUrl)
                            }}>
                            </div>
                        </div>
                    </div>
                    <div className="interval-56" />

                    {/*岗位要求模块开始 */}
                    <div className="job-component">
                        <div className="job-icon"></div>
                        <div className="job-component-main">
                            <div className="job-component-title">岗位要求</div>
                            <div className="job-component-text" dangerouslySetInnerHTML={{
                                __html:
                                    this.state.careers.JobsRequirementHtml && this.state.careers.JobsRequirementHtml.replaceAll('http://47.96.72.83:8086', global.config.serverUrl)
                            }}>
                            </div>
                        </div>
                    </div>
                    <div className="interval-56" />

                    {/* 投递方式模块开始*/}
                    <div className="job-component">
                        <div className="job-icon"></div>
                        <div className="job-component-main">
                            <div className="job-component-title">简历投递方式</div>
                            <div className="job-component-text">
                                <p><span>1、简历投递邮箱：hr@newgrand.cn；</span><br />
                                    <span>2、邮件标题格式：岗位名称+姓名</span><br />
                                    <span>3、联系电话：0571-88270588，传真：0571-88270219；</span><br />
                                    <span>4、公司官网：http://www.newgrand.cn；</span><br />
                                    <span>5、公司地址：杭州市祥园路88号中国（杭州）智慧信息产业园N座9F。</span><br />
                                    <span>6、更多招聘信息，请关注我们的官方微信。</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="interval-56" />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default JobDetails;