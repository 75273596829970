import React from "react";
import { useState } from "react";
// import Axios from "axios";
import { useHistory } from "react-router-dom";
// import $ from "jquery";

// 组件
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import Video from "../../../new-components/Video";
import IconItem from "../../../new-components/IconItem";
import IconTitleItem from "../../../new-components/IconTitleItem";
import ContactBottom from '../../../new-components/ContactBottom';

// 高阶组件
import RenderWithTitle from '../../../high-orders/RenderWithTitle';
import RenderWithMoreBtn from '../../../high-orders/RenderWithMoreBtn';

import './index.less';

// 配置
import config from './config';
import "../../../global.js";

function SessionC(config) {
  const row1 = config.items.slice(0, 4);
  const row2 = config.items.slice(4);

  return (
    <div className="session-c">
      <div className="row" >
        {
          row1.map((el) => {
            return (
              <IconItem
                className="item"
                key={el.key}
                describe={el.describe}
                iconStyle={{
                  backgroundSize: `calc(800vw/14.4)`,
                  backgroundImage: config.default.backgroundImage,
                  backgroundPosition: `calc(${-10 - el.iconPosition.x * 100}vw/14.4) calc(${-10 - el.iconPosition.y * 100}vw/14.4)`,
                }}
              />)
          })
        }
      </div>

      <div className="row">
        {
          row2.map((el) => {
            return (
              <IconItem
                className="item"
                key={el.key}
                describe={el.describe}
                iconStyle={{
                  backgroundSize: `calc(800vw/14.4)`,
                  backgroundImage: config.default.backgroundImage,
                  backgroundPosition: `calc(${-10 - el.iconPosition.x * 100}vw/14.4) calc(${-10 - el.iconPosition.y * 100}vw/14.4)`,
                }}
              />)
          })
        }
      </div>
    </div>
  )
}

function SessionE(config) {
  const rowItemCount = 2;
  const rowCount = 3;
  const inner = [];
  const [showDetail, setShowDetail] = useState(false);

  for (let rowIndex = 0; rowIndex < rowCount; rowIndex += 1) {
    let currRow = [];

    for (let colIndex = 0; colIndex < rowItemCount; colIndex += 1) {
      let currItemConfig = config.items[rowIndex * rowItemCount + colIndex];
      if (!currItemConfig) {
        break;
      }

      let item = <IconTitleItem
        key={currItemConfig.key}
        describe={currItemConfig.describe}
        iconStyle={{
          backgroundSize: `calc(800vw/14.4)`,
          backgroundImage: config.default.backgroundImage,
          backgroundPosition: `calc(${-25 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-25 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
        }}
        title={currItemConfig.title}
      />

      if (rowIndex === 0 && colIndex === 0) {
        currRow.push(
          (<div key={currItemConfig.key}>
            {item}
            <div onClick={() => { setShowDetail(true) }} className="moreInfoBtn"><span>展开》</span></div>
          </div>)
        );
      } else {
        currRow.push(item);
      }
    }

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  }

  function InfoCard(props) {
    return (
      <div className="info-card">
        <div className="middle-inner">
          <div className='title'>{props.title}</div>
          <div className="text">{props.text}</div>
        </div>
      </div>
    )
  }
  function InfoContainer(props) {
    return (
      <div className="info-card-container"
        style={{ display: props.showDetail ? 'auto' : 'none' }}
      >
        <div className={`info-card-container-mask`} onClick={() => { props.onMaskClick && props.onMaskClick() }}></div>
        <div className="info-card-content">
          {
            props.extraInfo.map((el, index) => {
              return <InfoCard {...el} key={index}></InfoCard>
            })
          }
        </div>
      </div>
    )
  }

  return <div className="session-e">
    {inner}
    {<InfoContainer
      extraInfo={config.extraInfo}
      showDetail={showDetail}
      onMaskClick={() => { setShowDetail(false) }}
    />}
  </div>
}

function SessionF(props) {
  const history = useHistory()
  return <div className="session-f">{props.items.map((el, index) => <img
    key={index}
    src={el.logoSrc}
    alt=""
    className="up-18"
    onClick={() => {
      history.push(el.history)
    }}
  />)}</div>
}

const SessionCWithTitle = RenderWithTitle(SessionC, "产品特点");

const SessionDWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionD.imgSrc} alt="" /></div>, "产品模块图", {
  background: `linear-gradient(360deg,rgba(241,255,255,0) 0%,rgba(242,245,246,1) 100%)`,
});

const SessionEWithTitle = RenderWithTitle(SessionE, "模块介绍", {
  backgroundColor: '#F2F5F6'
});



export default () => {
  const history = useHistory();

  const SessionFWithTitleAndMoreBtn = RenderWithTitle(RenderWithMoreBtn(SessionF, '更多案例 >', {}, () => {
    history.push('/caseof11/i8工程项目管理平台/产品')
  }), '用户案例');

  return (
    <div className="new-i8-page">
      <Head />

      <Abstract {...config.sessionA} />

      <Video {...config.sessionB} />

      <SessionCWithTitle {...config.sessionC} />

      <SessionDWithTitle />

      <SessionEWithTitle {...config.sessionE} />

      <SessionFWithTitleAndMoreBtn {...config.sessionF} />

      <ContactBottom />

      <Bottom />
    </div>
  )
};