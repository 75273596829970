import React, { useState } from "react";
// import Axios from "axios";
import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
// import $ from "jquery";

// 组件
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import Video from "../../../new-components/Video";
import IconItem from "../../../new-components/IconItem";
import IconTitleItem from "../../../new-components/IconTitleItem";
import ContactBottom from '../../../new-components/ContactBottom';

// 高阶组件
import RenderWithTitle from '../../../high-orders/RenderWithTitle';
import RenderWithMoreBtn from '../../../high-orders/RenderWithMoreBtn';

import './index.less';

// 配置
import config from './config';
import "../../../global.js";

function SessionC(config) {
  const colCount = config.colCount
  const rowCount = config.rowCount
  const inner = [];

  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconItem className="item" key={currItemConfig.key} describe={currItemConfig.describe} iconStyle={{
          backgroundImage: config.default.backgroundImage,
          backgroundPosition: `calc(${-10 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-10 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
        }} />))
    });
    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return <div className="session-c"> {inner}</div>
}

function SessionE(config) {
  const colCount = config.colCount;
  const rowCount = config.rowCount;
  const onClick = config.toggleModules;

  const inner = [];
  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconTitleItem key={currItemConfig.key} describe={currItemConfig.describe} title={currItemConfig.title}
          iconStyle={{
            backgroundImage: config.default.backgroundImage,
            backgroundPosition: `calc(${-25 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-25 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
          }}
        />))
    });
    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return (<div className="session-e">
    {inner}
    <div className="btn">
      {(() => {
        if (rowCount === 2) {
          return <div onClick={() => { onClick && onClick("open") }}><div className="down"></div><div className="text">展开</div></div>
        } else {
          return <div onClick={() => { onClick && onClick("close") }}><div className="up" ></div><div className="text">收起</div></div>
        }
      })()}
    </div>
  </div>)
}

function SessionF(props) {
  const history = useHistory()
  return <div className="session-f">{props.items.map((el, index) => <img
    key={index}
    src={el.logoSrc}
    alt=""
    className="up-18"
    onClick={() => {
      history.push(el.history)
    }}
  />)}</div>
}

const SessionCWithTitle = RenderWithTitle(SessionC, "产品特点", 5, 2);

const SessionDWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionD.imgSrc} alt="" /></div>, "产品模块图", {
  background: `linear-gradient(360deg,rgba(241,255,255,0) 0%,rgba(242,245,246,1) 100%)`,
});

const SessionEWithTitle = RenderWithTitle(SessionE, "系统模块", { backgroundColor: '#F2F5F6' });

export default () => {
  const [rowCount, setRowCount] = useState(2);
  const colCount = 2;
  const history = useHistory();

  const SessionFWithTitleAndMoreBtn = RenderWithTitle(RenderWithMoreBtn(SessionF, '更多案例 >', {}, () => {
    history.push('/caseof12/mi8工程项目管理云平台/产品')
  }), '用户案例');

  function handleToggleModules(action) {
    if (action === 'open') {
      setRowCount(4);
    } else {
      setRowCount(2);
    }
  }

  return (
    <div className="new-mi8-page">
      <Head />

      <Abstract {...config.sessionA} />

      <Video {...config.sessionB} />

      <SessionCWithTitle {...config.sessionC} />

      <SessionDWithTitle {...config.sessionD} />

      <SessionEWithTitle {...config.sessionE} colCount={colCount} rowCount={rowCount} toggleModules={handleToggleModules} />

      <SessionFWithTitleAndMoreBtn {...config.sessionF} />

      <ContactBottom />

      <Bottom />
    </div>
  )
};