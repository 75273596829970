/*
 * @Author: your name
 * @Date: 2019-12-18 13:14:25
 * @LastEditTime: 2022-01-12 17:15:38
 * @LastEditors: qyy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\home\Home.js
 */
import "../../global.js";
import React from "react";
//import './index.css';
import "./home.less";
import $ from "jquery";

//引入prefeb模块
import Bottom from "../../common-components/BottomBar";
import Head from "../../common-components/TopBar/TopBar";
import Title from "../../common-components/title/Title";
import CardB from "../../common-components/card-b/CardB";
import CardA from "../../common-components/card-a/CardA";
import CardD from "../../common-components/card-d/CardD";
import AdFooter from "./bannerFooter/BannerFooter";
import { Link } from "react-router-dom";
import WheelPlanting from "../../common-components/wheel-planting/WheelPlanting";
import Axios from "axios";

// import homeVideo from '../../picture/bannerbg2.mp4';
let homeVideo =
  global.config.url + "/NewGrand/media/newgrand20180913.da890242.mp4";

//页面第一个模块
class FirstPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false //默认不选择
    };
  }
  playvideo(isture) {
    this.setState({
      isShow: isture
    });
  }

  render() {
    const isShow = this.state.isShow;
    let myvideo;
    if (isShow === true) {
      myvideo = (
        <div className="first-page-vedio-box">
          <div
            className="first-page-vedio-bd"
            onClick={() => this.playvideo(false)}
          ></div>
          <div className="first-page-vedio-margin">
            <div
              className="first-page-video-exit"
              onClick={() => this.playvideo(false)}
            >
              ×
            </div>
          </div>
          <div className="first-page-vedio">
            <video autoPlay="autoplay" loop="loop">
              <source src="movie.ogg" type="video/ogg" />
              <source src={homeVideo} type="video/mp4" />
              <source src="movie.webm" type="video/webm" />
              <object data="movie.mp4" width="100%" height="100%">
                <embed width="100%" height="100%" src="movie.swf" />{'>'}
              </object>
            </video>
          </div>
        </div>
      );
    } else {
      myvideo = <div></div>;
    }
    //1：天下没有难管的项目
    const video1 = (
      <div className="first-page-show">
        {/* <div className="first-page-show-logo"></div> */}
        <div
          className="first-page-show-icon"
          onClick={() => this.playvideo(true)}
        ></div>
      </div>
    );
    //2：行业占比
    const video2 = (
      // <a href="https://xy.inewgrand.com/index" target="_blank" rel="noopener noreferrer">
      <Link to="/newsDetails/2235">
        <div className="first-page-show">
          <img
            alt=""
            src={global.config.url + "/newgrand/picture/img/首页/官网海报04.png"}
          />
        </div>
      </Link>
      // </a>
    );
    //3：i8
    const video3 = (
      <Link to="/i8">
        <div className="first-page-show">
          <img
            alt=""
            src={
              global.config.url +
              "/newgrand/picture/img/首页/官网海报03.png"
            }
          />
        </div>
      </Link>
    );

    //4：集团财务
    // const video4 = (
    //   <Link to="/groupFinance">
    //     <div className="first-page-show">
    //       <img
    //         alt=""
    //         src={
    //           global.config.url + "/newgrand/picture/img/首页/官网海报05.png"
    //         }
    //       />
    //     </div>
    //   </Link>
    // );
    // 新基建
    const video4 = (
      // <Link onClick={()=> {  }}>
      <a className="first-page-show" href="https://mp.weixin.qq.com/s/BPoUaY_pQvKBrkJuLBUocg" target="_blank" rel="noopener noreferrer">
        <img
          alt=""
          src={
            global.config.url + "/newgrand/picture/img/banner/znjz.jpg"
          }
        />
      </a>
      // </Link>
    );
    //5：实名制
    const video5 = (
      <Link to="/realNameLaborManager">
        <div className="first-page-show">
          <img
            alt=""
            src={
              global.config.url + "/newgrand/picture/img/首页/官网海报06.png"
            }
          />
        </div>
      </Link>
    );
    //6：税务发票
    const video6 = (
      <Link to="/taxAdministration">
        <div className="first-page-show">
          <img
            alt=""
            src={
              global.config.url + "/newgrand/picture/img/首页/官网海报02.png"
            }
          />
        </div>
      </Link>
    );
    return (
      <div className="first-page">
        {/*视频播放 */}
        {myvideo}
        <WheelPlanting
          show1={video4}
          show2={video2}
          show3={video1}
          show4={video3}
          show5={video5}
        // show6={video5}
        />
        <AdFooter />
        <div id="home-1-title">
          <Title text={"产品一览"} />
        </div>
        <div className="first-page-list " id="home-1-list">
          <Link to="/i8">
            <div className="first-page-card">
              <div className="first-page-card-img">
                <img
                  alt="浏览器版本过低，无法显示图片"
                  src={
                    global.config.url +
                    "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E4%BA%A7%E5%93%81%E6%A1%88%E5%88%97%E6%96%B0%E9%97%BB/image-2.png"
                  }
                ></img>
              </div>
              <div className="first-page-card-title">i8 工程项目管理平台</div>
              <div className="first-page-card-text">
                新中大深耕工程建设行业近20年，不断整合行业专家资源，汇聚众多优秀建筑企业管理实践经验，
                5年磨一剑，打造出智慧建造4.0时代的战略产品i8，
                为移动互联时代建筑企业转型升级提供一站式信息化解决方案。
              </div>
            </div>
          </Link>
          <div className="interval-col-45" />
          <Link to="/d6e">
            <div className="first-page-card">
              <div className="first-page-card-img">
                <img
                  alt="浏览器版本过低，无法显示图片"
                  src={
                    global.config.url +
                    "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E4%BA%A7%E5%93%81%E6%A1%88%E5%88%97%E6%96%B0%E9%97%BB/image-1.png"
                  }
                ></img>
              </div>
              <div className="first-page-card-title">D6e 企业级数字工地管理软件</div>
              <div className="first-page-card-text">
                D6e 软件是助力建筑企业在智慧建造4.0时代快速数字化转型的一站式管理应用平台。
                产品从多角度实现现场人、机、料、法、环、测六要素的组合监管，数据实时上传，统一看板分析，满足企业远程监管需求。
              </div>
            </div>
          </Link>
          <div className="interval-col-45" />
          <Link to="/mini">
            <div className="first-page-card">
              <div className="first-page-card-img">
                <img
                  alt="浏览器版本过低，无法显示图片"
                  src={
                    global.config.url +
                    "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E4%BA%A7%E5%93%81%E6%A1%88%E5%88%97%E6%96%B0%E9%97%BB/image-3.png"
                  }
                ></img>
              </div>
              <div className="first-page-card-title">mi8工程项目管理云平台</div>
              <div className="first-page-card-text">
                mi8工程项目管理云平台是一款针对成长型建筑企业量身打造的简单易用、
                快速部署的信息化普及产品，产品包括合同资金云、经济成本云、物资云、办公云、发票税务云、
                现场管理云6大解决方案。
              </div>
            </div>
          </Link>
        </div>
        <div className="first-page-link"></div>
      </div>
    );
  }
}

//页面第二个模块
class SecondPage extends React.Component {
  handleClick(address) {
    window.location.href = address;
  }

  componentDidMount() {
    document.getElementById("second-button").onclick = this.handleClick.bind(
      this,
      "http://inewgrand.newgrand.cn"
    );
  }
  changedown() {
    document.getElementById("second-1").style.transform = "translateY(0)";
    document.getElementById("second-2").style.transform = "translateY(0)";
    document.getElementById("main-text").innerHTML =
      "新云汇是新中大旗下的网上综合商城平台，为新中大的客户、代理商提供产品的下单、付款和开户等一站式服务。";
    document.getElementById("second-button").onclick = this.handleClick.bind(
      this,
      "http://inewgrand.newgrand.cn"
    );
  }

  changeup() {
    document.getElementById("second-1").style.transform =
      "translateY(19.375vw)";
    document.getElementById("second-2").style.transform =
      "translateY(19.375vw)";
    document.getElementById("main-text").innerHTML =
      "新中大云服务平台是连接新中大用户、技术支持、产品研发的沟通桥梁。平台包含在线咨询、新中大知道、新中大百科、在线运维等功能。";
    document.getElementById("second-button").onclick = this.handleClick.bind(
      this,
      "http://ns820.newgrand.cn"
    );
  }
  render() {
    return (
      <div className="second-page " id="home-2">
        <div className="second-page-card"></div>
        <div className="second-page-card-img1"></div>
        <div className="second-page-card-img2"></div>
        <div className="second-page-card-title1">新云汇</div>
        <div className="second-page-card-text1">
          新云汇是新中大旗下的网上综合商城平台，为新中大的客户、代理商提供产品的下单、付款和开户等一站式服务。
        </div>
        <div className="second-page-card-title2">云服务平台</div>
        <div className="second-page-card-text2">
          新中大云服务平台是连接新中大用户、技术支持、产品研发的沟通桥梁。
        </div>
        <div className="second-page-yellow-card" id="second-1"></div>
        <div
          className="second-page-card-up"
          onMouseEnter={() => this.changedown()}
        ></div>
        <div
          className="second-page-card-down"
          onMouseEnter={() => this.changeup()}
        ></div>
        <div className="triangle" id="second-2"></div>
        <div className="second-page-title">详细介绍</div>
        <div className="second-page-text" id="main-text">
          云计算平台可以划分为3类:以数据存储为主的存储型云平台,以数据处理为主的计算型云平台以
          及计算和数据存储处理兼顾的综合云计算平台云计算平台可以划分为3类:以数据存储为主的存储型云平台
          ,以数据处理为主的计算型云台……
        </div>
        <div className="second-page-botton botton-yellow" id="second-button">
          了解更多 {'>'}
        </div>
      </div>
    );
  }
}

//网页第三个模块
class ThridPage extends React.Component {
  render() {
    return (
      <div className="third-page">
        <div id="home-3-title">
          <Title text={"解决方案"} />
        </div>
        <div className="third-page-col1" id="home-3-list">
          <Link
            to={{
              pathname: "/groupControl",
              state: {
                num: 4,
                nowlocation: 1
              }
            }}
          >
            <div className="change1">
              <CardB text={"集团管控"} />
            </div>
          </Link>
          {/* <div className="interval-col-50" />
          <Link to="/investment">
            <div className="change2">
              <CardB text={"项目投资"} />
            </div>
          </Link>
          <div className="interval-col-50" />
          <Link to="/projectConstruction">
            <div className="change3">
              <CardB text={"项目建造"} />
            </div>
          </Link> */}
          <div className="interval-col-50" />
          <Link to="/projectManufacturing">
            <div className="change4">
              <CardB text={"项目制造"} />
            </div>
          </Link>
          <div className="interval-col-50" />
          <Link to="/projectManufacturing">
            <div className="change4">
              <CardB text={"项目服务"} />
            </div>
          </Link>
          <div className="interval-col-50" />
          <Link to="/projectManufacturing">
            <div className="change4">
              <CardB text={"移动应用"} />
            </div>
          </Link>
        </div>
        {/* <div className="third-page-col2" id="home-3-list2">
          <Link to="/projectService">
            <div className="change5">
              <CardB text={"项目服务"} />
            </div>
          </Link>
          <div className="interval-col-50" />
          <Link to="/digitalSite">
            <div className="change6">
              <CardB text={"数字工地"} />
            </div>
          </Link>
          <div className="interval-col-50" />
          <Link to="/siteCore">
            <div className="change7">
              <CardB text={"工地芯"} />
            </div>
          </Link>
          <div className="interval-col-50" />
          <Link to="/mobileApplication">
            <div className="change8">
              <CardB text={"移动应用"} />
            </div>
          </Link>
        </div> */}
      </div>
    );
  }
}

//网页第四个模块

class FourthPage extends React.Component {
  constructor(props) {
    super(props);
  }
  handleClick(address) {
    this.props.history.push(address);
  }
  render() {
    return (
      <div className="fourth-page i8">
        <div id="home-4-title">
          <Title text={"成功案例"} />
        </div>
        <div className="fourth-page-show-customer" id="i8-4-col">
          <div className="fourth-page-show-customer-groove-head">
            <div className="up-18 shadow">
              <div
                className="customer-pictures-img"
                onClick={this.props.click(1129)}
              >
                <div className="">
                  <img
                    src={
                      global.config.url +
                      "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E4%BA%A7%E5%93%81%E6%A1%88%E5%88%97%E6%96%B0%E9%97%BB/shanghai.png"
                    }
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="fourth-page-show-customer-groove-next">
            <div className="up-18 shadow">
              <div
                className="customer-pictures-img"
                onClick={this.props.click(1005)}
              >
                <div className="">
                  <img
                    src={
                      global.config.url +
                      "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E4%BA%A7%E5%93%81%E6%A1%88%E5%88%97%E6%96%B0%E9%97%BB/shanxi.png"
                    }
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="fourth-page-show-customer-groove-next">
            <div className="up-18 shadow">
              <div
                className="customer-pictures-img"
                onClick={this.props.click(1007)}
              >
                <div className="">
                  <img
                    src={
                      global.config.url +
                      "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E4%BA%A7%E5%93%81%E6%A1%88%E5%88%97%E6%96%B0%E9%97%BB/jinan.png"
                    }
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="fourth-page-show-customer-groove-next">
            <div className="up-18 shadow">
              <div
                className="customer-pictures-img"
                onClick={this.props.click(1025)}
              >
                <div className="customer-img">
                  <img
                    src={
                      global.config.url +
                      "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E4%BA%A7%E5%93%81%E6%A1%88%E5%88%97%E6%96%B0%E9%97%BB/gh.jpg"
                    }
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fourth-page-all" onClick={this.props.handleClick}>
          全部案例 {'>'}
        </div>
      </div>
    );
  }
}

//网页第五个模块
class FifthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: { code: 0, msg: "操作成功", count: 1, data: [] },
      arr: []
    };
  }

  componentDidMount() {
    Axios.get(global.config.serverUrl + "/NewsInfo/LoadData/?page=1&limit=10")
      .then(news => {
        console.log(news.data);
        this.setState({ news: news.data });
      })
      .catch(error => {
        console.log(error);
      });
  }
  render() {
    var newsListLeft = [];
    if (this.state.news.data.length >= 3) {
      newsListLeft.push(
        <div key={"1"} className="fifth-page-col" id="home-5-list">
          <CardD
            img={
              global.config.serverUrl +
              this.state.news.data[0].NewsContentFilePath
            }
            text={this.state.news.data[0].NewsName.substring(0, 20) + "..."}
            time={this.state.news.data[0].NewsTypeDesc}
            click={this.props.handleClickid(this.state.news.data[0].Id)}
          />
          <div className="interval-col-50" />
          <CardD
            img={
              global.config.serverUrl +
              this.state.news.data[1].NewsContentFilePath
            }
            text={this.state.news.data[1].NewsName.substring(0, 20) + "..."}
            time={this.state.news.data[1].NewsTypeDesc}
            click={this.props.handleClickid(this.state.news.data[1].Id)}
          />
          <div className="interval-col-50" />
          <CardD
            img={
              global.config.serverUrl +
              this.state.news.data[2].NewsContentFilePath
            }
            text={this.state.news.data[2].NewsName.substring(0, 20) + "..."}
            time={this.state.news.data[2].NewsTypeDesc}
            click={this.props.handleClickid(this.state.news.data[2].Id)}
          />
        </div>
      );
    }
    return (
      <div className="fifth-page">
        <div id="home-5-title">
          <Title text={"新闻动态"} />
        </div>
        {newsListLeft}
        <div className="fifth-page-all">
          <Link to="/news">全部新闻 {'>'}</Link>
        </div>
      </div>
    );
  }
}
//网页主模块
class Home extends React.Component {
  //跳转传参
  handleClickid(address, myid) {
    this.props.history.push(address + "/" + myid);
  }

  //跳转传参
  handleClick(address) {
    this.props.history.push(address, {
      dotData: "成功了"
    });
  }
  //----从此处开始
  constructor(props) {
    super(props);
    this.showAnimation = this.showAnimation.bind(this);
  }
  //离开解绑滚动条事件
  componentWillUnmount() {
    window.removeEventListener("scroll", this.showAnimation);
  }
  //绑定滚动条事件
  componentDidMount() {
    window.addEventListener("scroll", this.showAnimation);
    this.showAnimation();
  }
  //获取相关id的高度
  getHight(id, showposition, classname) {
    let height =
      $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
    if (height < showposition) {
      $("#" + id).addClass(classname);
    }
  }
  //在这里绑定滚动播放动画事件 （具体绑定每个id）
  showAnimation() {
    /*
        this.getHight("home-1-title", 200, "moveup");
        this.getHight("home-1-list", 200, "moveup");
        this.getHight("home-3-title", 200, "moveup");
        this.getHight("home-3-list", 200, "moveup");
        this.getHight("home-3-list2", 200, "moveup");
        this.getHight("home-4-title", 200, "moveup");
        this.getHight("home-2", 0, "opacity-change");
        this.getHight("home-5-title", 200, "moveup");
        this.getHight("home-5-list", 200, "moveup");
        */
  }
  //-----到这里结束
  render() {
    return (
      <div>
        <Head />
        <div className="home">
          <FirstPage />
          <ThridPage />
          <FourthPage
            click={myid => this.handleClickid.bind(this, "/caseDetails", myid)}
            handleClick={this.handleClick.bind(this, "/case/all")}
          />
          {/* <SecondPage /> */}
          <FifthPage
            handleClickid={myid =>
              this.handleClickid.bind(this, "/newsDetails", myid)
            }
          />
        </div>
        <Bottom />
      </div>
    );
  }
}

export default Home;
