/*
 * @Author: your name
 * @Date: 2019-12-18 15:52:24
 * @LastEditTime : 2020-01-13 11:21:44
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\contact-us\ContactUs.js
 */
import React from 'react';
import './contact-us.less';

//引入prefeb模块
import Bottom from '../../common-components/BottomBar'
import Head from '../../common-components/TopBar/TopBar'
import List from '../../common-components/list/List'
import { Link } from 'react-router-dom'
import Axios from 'axios';


//网页第一个模块
class FirstPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companys: { "code": 0, "msg": "操作成功", "count": 1, "data": [] },
            arr: []
        }
    }

    //创建一个方法让子组件调用
    openInfoWindow2() {

    }
    GetName(company) {
        const { BMap } = window
        var point = new BMap.Point(company.Latitude, company.Longitude)
        this.openInfoWindow2(company.GroupAddress, point, company.GroupName, company.OfficePhone);

    }
    componentDidMount() {
        Axios.get(global.config.serverUrl + '/GroupInfo/LoadData/?page=1&limit=100&key=')
            .then((companys) => {
                console.log(companys.data);
                this.setState({ companys: companys.data })
            })
            .catch((error) => {
                console.log(error);
            });

        const { BMap } = window
        var map = new BMap.Map("allmap"); // 创建Map实例
        var point = new BMap.Point(120.2193, 30.25924)//创建所在坐标位置
        map.centerAndZoom(point, 15); // 初始化地图,设置中心点坐标和地图级别
        map.addControl(new BMap.NavigationControl()); //添加地图的缩放控件
        map.addControl(new BMap.ScaleControl());    //添加地图的比例尺控件
        map.addControl(new BMap.MapTypeControl());  //添加地图类型
        map.enableScrollWheelZoom(true); //开旗滚轮缩放
        map.enableBizAuthLogo();
        this.openInfoWindow2 = function (data, point, name, phone) {
            /*
            var searchInfoWindow2 = new BMapLib.SearchInfoWindow(map, data, {
                title: name, //标题
                width: 282, //宽度
                height: 100, //高度
                panel: "panel", //检索结果面板
                enableAutoPan: true, //自动平移
                searchTypes: [
                ]
            });*/
            if (phone === null) {
                phone = " ";
            }
            var marker = new BMap.Marker(point);
            map.addOverlay(marker);
            //searchInfoWindow2.open(point);
            var opts = {
                width: 282,     // 信息窗口宽度
                height: 146,     // 信息窗口高度
                title: name, // 信息窗口标题
                enableMessage: true,//设置允许信息窗发送短息
                offset: { height: -25, width: 0 },
                enableAutoPan: true,
            };
            var infoWindow = new BMap.InfoWindow("<br/><div class=\"address\">" + data + "</div><br/><div class=\"phone\">" + phone + "</div>", opts);
            map.openInfoWindow(infoWindow, point);
        }


    }
    render() {

        var campanyList = []
        for (let i = 0; i < this.state.companys.data.length; i++) {
            if (this.state.companys.data[i].GroupType === 0 && this.state.companys.data[i].IsUseful === 1) {
                campanyList.push(<List key={i} name={this.state.companys.data[i].GroupName} adress={this.state.companys.data[i].GroupAddress}
                    phone={this.state.companys.data[i].OfficePhone} Getname={this.GetName.bind(this, this.state.companys.data[i])} />)
            }
        }
        return (
            <div className="first-page" >
                <div className="first-page-title">联系我们</div>

                <div className="first-page-show">
                    <div className="left">
                        <div className="left-title">
                            <div className="left-title-botton1">分支机构</div>
                            {/* <div className="left-title-botton2"><Link to="/contactUs2">集团成员</Link></div> */}
                        </div>
                        <div className="company-list">
                            {campanyList}
                        </div>
                    </div>
                    <div className="right">
                        <div className="map">
                            <div id="allmap" style={{ width: '100%', height: '100%' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页主模块
class ContactUs extends React.Component {
    render() {
        return (
            <div>
                <Head />
                <div className="contact-us">
                    <FirstPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default ContactUs;