/*
 * @Author: your name
 * @Date: 2019-12-25 10:59:43
 * @LastEditTime : 2020-01-09 13:45:53
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\common-components\guide\Guide.js
 */
import '../../global.js';
import React from 'react';
//import './index.css';
import './guide.less'
import { Link } from 'react-router-dom'

/**
 * @description  提供页面右侧导航栏服务
 * @author chenyutan
 * @date 2019-12-11
 * @class Describe
 */
class Guide extends React.Component {
    changeActive(divId) {
        document.getElementById(divId).style.transform = "translateX(" + -320 / 14.4 + "vw)";
    }

    changeUnactive(divId) {
        document.getElementById(divId).style.transform = "translateX(0vw)";
    }

    telChangeActive(divId) {
        document.getElementById(divId).style.transform = "translateX(" + -480 / 14.4 + "vw)";
    }

    telChangeUnactive(divId) {
        document.getElementById(divId).style.transform = "translateX(0vw)";
    }

    foldGuide() {
        document.getElementById("guide-unfold").style.display = "none";
        document.getElementById("guide-fold").style.display = "inline";
    }

    unfoldGuide() {
        document.getElementById("guide-unfold").style.display = "inline";
        document.getElementById("guide-fold").style.display = "none";
    }

    render() {
        return (
            <div className="common">
                <div className="guide" id="guide-unfold">
                    <div className="guide-icon">
                        <a href="http://ns820.newgrand.cn">
                            <div>
                                <div className="guide-icon-img">
                                    <img alt="" src={global.config.url + "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E5%AE%A2%E6%9C%8D/%E4%BA%91%E7%AB%AF.png"}></img>
                                </div>
                                <div className="guide-icon-text">云服务</div>
                            </div>
                        </a>
                    </div>
                    <a href="http://inewgrand.com/#/chatroom" target="_blank" rel="noopener noreferrer">
                        <div className="guide-icon">
                            <div>
                                <div className="guide-icon-img">
                                    <img alt="" src={global.config.url + "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E5%AE%A2%E6%9C%8D/销售员%20copy.png"}></img>
                                </div>
                                <div className="guide-icon-text">业务咨询</div>
                            </div>
                        </div>
                    </a>
                    <div className="guide-icon" onMouseEnter={() => this.telChangeActive("phone")} onMouseLeave={() => this.telChangeUnactive("phone")}>
                        <div>
                            <div className="guide-icon-img">
                                <img alt="" src={global.config.url + "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E5%AE%A2%E6%9C%8D/电话%20copy.png"}></img>
                            </div>
                            <div className="guide-icon-text">客服电话</div>
                        </div>
                    </div>
                    {/* <div className="guide-icon" onMouseEnter={() => this.changeActive("official-account")} onMouseLeave={() => this.changeUnactive("official-account")}>
                        <div>
                            <div className="guide-icon-img">
                                <img alt="" src={global.config.url + "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E5%AE%A2%E6%9C%8D/分组%2016%20copy.png"}></img>
                            </div>
                            <div className="guide-icon-text">官方微信</div>
                        </div>
                    </div>
                    <div className="guide-icon" onMouseEnter={() => this.changeActive("micro-blog")} onMouseLeave={() => this.changeUnactive("micro-blog")}>
                        <div>
                            <div className="guide-icon-img">
                                <img alt="" src={global.config.url + "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/%E5%AE%A2%E6%9C%8D/分组%2015%20copy.png"}></img>
                            </div>
                            <div className="guide-icon-text">官方微博</div>
                        </div>
                    </div> */}
                    <div className="guide-icon" onClick={() => this.foldGuide()}>
                        <div>
                            <div className="guide-icon-fold">
                                <img alt="" src={global.config.url + "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/fold.png"}></img>
                            </div>
                        </div>
                    </div>
                    <div className="guide-position">
                        <div className="guide-popup-1" id="micro-blog" onMouseEnter={() => this.changeActive("micro-blog")} onMouseLeave={() => this.changeUnactive("micro-blog")}>
                            <div className="guide-popup-1-in"></div>
                        </div>
                        <div className="guide-popup-1" id="official-account" onMouseEnter={() => this.changeActive("official-account")} onMouseLeave={() => this.changeUnactive("official-account")}>
                            <div className="guide-popup-2-in"></div>
                        </div>
                        <div className="guide-popup-tel" id="phone" onMouseEnter={() => this.telChangeActive("phone")} onMouseLeave={() => this.telChangeUnactive("phone")}>
                            <div className="tel-head">
                                <div className="tel-head-img"></div>
                                <div className="tel-head-text">您好，我们随时为您提供帮助～</div>
                            </div>
                            <div className="tel-body-col1">
                                <div className="tel-body-col1-text1">热线电话：</div>
                                <div className="tel-body-col1-text2">95105-820</div>
                            </div>
                            <div className="tel-body-col2">
                                <div className="tel-body-col2-icon"></div>
                                <Link to="/contactUs">
                                    <div className="tel-body-col2-text">各地联系方式</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="guide-fold" id="guide-fold" onClick={() => this.unfoldGuide()}>
                    <div className="guide-icon-unfold">
                        <img alt="" src={global.config.url + "/newgrand/picture/img/%E9%A6%96%E9%A1%B5/unfold.png"}></img>
                    </div>
                </div>
            </div>
        );
    }
}

export default Guide;