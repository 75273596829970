import React from 'react';
//import './index.css';

import './topbar.less'
import { Link } from 'react-router-dom'
import '../../transition/change-color/color.less'

import { withRouter } from "react-router-dom";

/**
 * @author chenyutan
 * @date 2019-12-06
 * @class Head
 * @description Head组件 网站的顶部导航栏
 */

//头部导航
//产品页
class Product extends React.Component {
    render() {
        return (
            <div className="head-common-panel" id="topbar-product" onMouseEnter={this.props.mouseEnter} onMouseLeave={this.props.mouseOut}>
                <div className="interval-col-265" style={{ width: 'calc(65vw/14.4)' }} />
                <div className="head-product-col" style={{ width: 'calc(220vw/14.4)' }}>
                    <div className="interval-40" />
                    <div className="head-product-title">大中型软件</div>
                    {/* <div className="head-product-child"><Link to="/i8">i8工程项目管理平台</Link></div> */}
                    {/* <div className="head-product-child" style={{width:'calc(220vw/14.4)'}}><Link to="/D6e">D6e企业级数字工地管理软件</Link></div> */}
                    <div className="head-product-child"><Link to="/i6s">i6s企业管理软件</Link></div>
                    <div className="head-product-child"><Link to="/i6">i6企业管理软件</Link></div>
                </div>
                <div className="interval-col-75" />
                <div className="head-product-col">
                    <div className="interval-40" />
                    <div className="head-product-title">中小型软件</div>
                    {/* <div className="head-product-child"><Link to="/i8c">i8c工程项目管理云平台</Link></div> */}
                    {/* <div className="head-product-child"><Link to="/mini">mi8工程项目管理云平台</Link></div> */}
                    <div className="head-product-child"><Link to="/a3">A3企业管理软件</Link></div>
                    <div className="head-product-child"><Link to="/GE">GE企业管理软件</Link></div>
                    <div className="head-product-child"><Link to="/se">Se<sup>+</sup>企业管理软件</Link></div>
                </div>
                {/* <div className="interval-col-75" />
                <div className="head-product-col">
                    <div className="interval-40" />
                    <div className="head-product-title">政务软件</div>
                    <div className="head-product-child"><a href="http://www.gdataware.cn">政云数据</a></div>
                </div> */}
                <div className="interval-col-75" />
                <div className="head-product-col">
                    <div className="interval-40" />
                    <div className="head-product-title">平台及门户</div>
                    <div className="head-product-child"><Link to="/UIC">供应商门户UIC</Link></div>
                    <div className="head-product-child"><Link to="/UP">柔性化统一平台SUP</Link></div>
                </div>
                {/* <div className="interval-col-75" />
                <div className="head-product-col">
                    <div className="interval-40" />
                    <div className="head-product-title">智能硬件</div>
                    <div className="head-product-child"><Link to="/siteCore">工地芯</Link></div>
                </div> */}
            </div>
        );
    }
}

//方案页
class Programme extends React.Component {
    render() {
        return (
            <div style={{
                display: "flex",
                // justifyContent: "center",
            }}
                className="head-common-panel" id="topbar-programme" onMouseEnter={this.props.mouseEnter} onMouseLeave={this.props.mouseOut}>
                <div className="interval-col-265" style={{ width: 'calc(65vw/14.4)' }} />
                <div className="head-programme-col">
                    <div className="interval-40" />
                    <div className="head-programme-title">集团管控</div>
                    <div className="head-programme-child"><Link to="/groupFinance">集团财务</Link></div>
                    <div className="head-programme-child"><Link to="/taxAdministration">税务管理</Link></div>
                    <div className="head-programme-child"><Link to="/cooperativeOffice">协同办公</Link></div>
                    <div className="head-programme-child"><Link to="/humanResources">集团人力资源</Link></div>
                </div>
                {/* <div className="interval-col-54" />
                <div className="head-programme-col">
                    <div className="interval-40" />
                    <div className="head-programme-title">项目投资</div>
                    <div className="head-programme-child"><Link to="/projectInvestment">业主投资</Link></div>
                    <div className="head-programme-child"><Link to="/largeProject">大型工程投资项目</Link></div>
                    <div className="head-programme-child"><Link to="/workShop">厂房新建(搬迁)</Link></div>
                </div> */}
                {/* <div className="interval-col-54" />
                <div className="head-programme-col">
                    <div className="interval-40" />
                    <div className="head-programme-title">项目建造</div>
                    <div className="head-programme-child"><Link to="/epc">工程总承包企业</Link></div>
                    <div className="head-programme-child"><Link to="/projectContracting">工程总承包企业</Link></div>
                    <div className="head-programme-child"><Link to="/constructionContract">施工总承包企业</Link></div>
                    <div className="head-programme-child"><Link to="/professionalContract">专业承包企业</Link></div>
                    <div className="head-programme-child" style={{ display: 'none' }}><Link to="/specialSolutions">专项解决方案</Link></div>
                </div> */}
                <div className="interval-col-54" />
                <div className="head-programme-col">
                    <div className="interval-40" />
                    <div className="head-programme-title">项目制造</div>
                    <div className="head-programme-child"><Link to="/projectManufacturing">项目制造方案</Link></div>
                </div>
                <div className="interval-col-54" />
                <div className="head-programme-col">
                    <div className="interval-40" />
                    <div className="head-programme-title">项目服务</div>
                    <div className="head-programme-child"><Link to="/lawyerProfession">律师行业解决方案</Link></div>
                    <div className="head-programme-child"><Link to="/softwareService">软件服务与系统集成</Link></div>
                    <div className="head-programme-child"><Link to="/processConsulting">全过程工程咨询服务</Link></div>
                </div>
                {/* <div className="interval-col-54" />
                <div className="head-programme-col">
                    <div className="interval-40" />
                    <div className="head-programme-title">数字工地</div>
                    <div className="head-programme-child"><Link to="/realNameLaborManager">企业级劳务实名制管理</Link></div>
                    <div className="head-programme-child"><Link to="/machineryManagement">现场机械管理</Link></div>
                    <div className="head-programme-child"><Link to="/materialManagement">现场物料管理</Link></div>
                    <div className="head-programme-child"><Link to="/onsiteIns">现场管理</Link></div>
                    <div className="head-programme-child"><Link to="/visualChemicalSiteManagement">标化工地</Link></div>
                    <div className="head-programme-child"><Link to="/siteCore">工地芯</Link></div>

                    <div className="head-programme-child" style={{ display: 'none' }}><Link to="/safetyEducation">安全教育</Link></div>
                    <div className="head-programme-child"><Link to="/interlligentPrintingControl">智能印控</Link></div>
                </div> */}
                <div className="interval-col-54" />
                <div className="head-programme-col">
                    <div className="interval-40" />
                    <div className="head-programme-title">移动应用</div>
                    <div className="head-programme-child"><Link to="/mobileApplication">移动应用解决方案</Link></div>
                </div>
            </div>
        );
    }
}
//案例页
class Example extends React.Component {
    render() {
        return (
            <div className="head-common-panel" id="topbar-example" onMouseEnter={this.props.mouseEnter} onMouseLeave={this.props.mouseOut}>
                <div className="interval-col-265" style={{ width: 'calc(65vw/14.4)' }} />
                <div className="head-example-col">
                    <div className="interval-40" />
                    <div className="head-about-title">产品案例</div>
                    <div className="head-example-col-flex">
                        <div className="head-example-col-left">
                            {/* <div className="head-example-child" onClick={this.props.caseclick("/caseof11", "产品", "i8工程项目管理平台")}>i8工程项目管理平台</div> */}
                            {/* <div className="head-example-child" onClick={this.props.caseclick("/caseof12", "产品", "mi8工程企业管理软件")}>mi8工程项目管理云平台</div> */}
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof13", "产品", "i6s企业管理软件")}>i6s企业管理软件</div>
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof27", "产品", "i6企业管理软件")}>i6企业管理软件</div>
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof14", "产品", "A3企业管理软件")}>A3企业管理软件</div>
                        </div>
                        <div className="head-example-col-left">
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof15", "产品", "GE企业管理软件")}>GE企业管理软件</div>
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof16", "产品", "SE企业管理软件")}>Se<sup>+</sup>企业管理软件</div>
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof17", "产品", "供应商门户UIC")}>供应商门户UIC</div>
                        </div>
                        <div className="head-example-col-right">
                            {/* <div className="head-example-child" onClick={this.props.caseclick("/caseof16", "产品", "SE企业管理软件")}>Se<sup>+</sup>企业管理软件</div>
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof17", "产品", "供应商门户UIC")}>供应商门户UIC</div> */}
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof17", "产品", "柔性化统一平台UP")}>柔性化统一平台SUP</div>
                        </div>
                    </div>
                </div>
                <div className="interval-col-210" />
                <div className="head-example-col">
                    <div className="interval-40" />
                    <div className="head-about-title" >领域案例</div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <div>
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof21", "业务领域", "集团管控")}>集团管控</div>
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof24", "业务领域", "项目制造")}>项目制造</div>
                            {/* <div className="head-example-child" onClick={this.props.caseclick("/caseof22", "业务领域", "项目投资")}>项目投资</div> */}
                            {/* <div className="head-example-child" onClick={this.props.caseclick("/caseof23", "业务领域", "项目建造")}>项目建造</div> */}
                        </div>
                        <div>
                            {/* <div className="head-example-child" onClick={this.props.caseclick("/caseof24", "业务领域", "项目制造")}>项目制造</div> */}
                            <div className="head-example-child" onClick={this.props.caseclick("/caseof25", "业务领域", "项目服务")}>项目服务</div>
                            {/* <div className="head-example-child" onClick={this.props.caseclick("/caseof26", "业务领域", "数字工地")}>数字工地</div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//云服务页
class Cloud extends React.Component {
    render() {
        return (
            <div
                className="head-common-panel"
                id="topbar-cloud"
                onMouseEnter={this.props.mouseEnter}
                onMouseLeave={this.props.mouseOut}
            >
                <div className="interval-col-700" />
                <div className="head-service-col">
                    <div className="interval-40" />
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">产品意见</a></div>
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">产品升级</a></div>
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">在线运维</a></div>
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">新中大百科</a></div>
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">新中大知道</a></div>
                </div>
                <div className="interval-col-100" />
                <div className="head-service-col">
                    <div className="interval-40" />
                    <div className="head-service-child"><a href="http://inewgrand.newgrand.cn">在线教程</a></div>
                    <div className="head-service-child"><a href="http://inewgrand.newgrand.cn">在线咨询</a></div>
                    <div className="head-service-child"><a href="http://inewgrand.newgrand.cn">注册验证</a></div>
                    <div className="head-service-child"><a href="http://inewgrand.newgrand.cn">投诉建议</a></div>
                </div>
            </div>
        );
    }
}

//服务页
class Service extends React.Component {
    render() {
        return (
            <div className="head-common-panel" id="topbar-service" onMouseEnter={this.props.mouseEnter} onMouseLeave={this.props.mouseOut}>
                <div className="interval-col-800" />
                {/* <div className="head-service-col">
                    <div className="interval-40" />
                    <div className="head-service-title">云服务平台</div>
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">在线坐席</a></div>
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">在线运维</a></div>
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">新中大百科</a></div>
                    <div className="head-service-child"><a href="http://ns820.newgrand.cn">投诉建议</a></div>
                </div> */}
                <div className="interval-col-250" />
                <div className="head-service-col">
                    <div className="interval-40" />
                    {/* <div className="head-service-title">新云汇</div> */}
                    <div className="head-service-child"><a href="http://inewgrand.newgrand.cn">mi8</a></div>
                    <div className="head-service-child"><a href="http://inewgrand.newgrand.cn">数字工地</a></div>
                    <div className="head-service-child"><a href="http://inewgrand.newgrand.cn">在线培训</a></div>
                    <div className="head-service-child"><a href="http://inewgrand.newgrand.cn">行业资讯</a></div>
                </div>
            </div>
        );
    }
}
//关于页
class About extends React.Component {

    render() {
        return (
            <div className="head-common-panel" id="topbar-about" onMouseEnter={this.props.mouseEnter} onMouseLeave={this.props.mouseOut}>
                {/* <div className="interval-col-247" />
                <div className="head-about-col">
                    <div className="interval-40" />
                    <div className="head-about-title">公司介绍</div>
                    <div className="head-about-child"><Link to="/companyProfile">企业介绍</Link></div>
                    <div className="head-about-child"><Link to="/corporateCulture">企业文化</Link></div>
                    <div className="head-about-child"><Link to="/corporateHonor">企业荣誉</Link></div>
                    <div className="head-about-child"><Link to="/developmentHistory">发展之路</Link></div>
                    <div className="head-about-child"><Link to="/managementLayer">管理层</Link></div>
                </div>
                <div className="interval-col-24" />
                <div className="head-about-col">
                    <div className="interval-40" />
                    <div className="head-about-title" ><Link to="/news">新闻动态</Link></div>
                    <div className="head-about-child" onClick={this.props.click("签约合作", "/news1")}>签约合作</div>
                    <div className="head-about-child" onClick={this.props.click("市场活动", "/news2")}>市场活动</div>
                    <div className="head-about-child" onClick={this.props.click("媒体聚焦", "/news3")}>媒体聚焦</div>
                    <div className="head-about-child" onClick={this.props.click("企业动态", "/news4")}>企业动态</div>
                </div>
                <div className="interval-col-24" />
                <div className="head-about-col">
                    <div className="interval-40" />
                    <div className="head-about-title">伙伴</div>
                    <div className="head-about-child"><Link to="/cooperationGuide">合作指南</Link></div>
                    <div className="head-about-child"><Link to="/partnerSupport">伙伴支持</Link></div>
                    <div className="head-about-child"><Link to="/joinApply">加盟申请</Link></div>
                    <div className="head-about-child"><Link to="/systemIntroduction">体系介绍</Link></div>
                    <div className="head-about-child"><Link to="/qualificationEnquiry">资质查询</Link></div>
                </div> */}
                <div className="interval-col-24" />
                <div className="head-about-col">
                    <div className="interval-40" />
                    <div className="head-about-title">加入我们</div>
                    <div className="head-about-child"><Link to="/joinNG">加入新中大</Link></div>
                    <div className="head-about-child"><Link to="/careerDevelopment">职业发展</Link></div>
                    {/* <div className="head-about-child"><Link to="/employeeLife">员工生活</Link></div> */}
                    <div className="head-about-child"><Link to="/talentRecruitment">人才招聘</Link></div>
                </div>
                {/* <div className="interval-col-24" />
                <div className="head-about-col">
                    <div className="interval-40" />
                    <div className="head-about-title">联系我们</div>
                    <div className="head-about-child"><Link to="/contactUs2">集团成员</Link></div>
                    <div className="head-about-child"><Link to="/contactUs">分支机构</Link></div>
                </div> */}
            </div>
        );
    }
}
class Head extends React.Component {
    constructor(props) {
        super(props)
        this.handleCaseClick = this.handleCaseClick.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleClickS = this.handleClickS.bind(this)
        this.keydown = this.keydown.bind(this)
        this.memudown = this.memudown.bind(this)
        this.menuup = this.menuup.bind(this)
    }

    handleClick(address, myid) {
        this.props.history.push(address + "/" + myid);
    }

    handleCaseClick(address, mycases, mytype) {
        this.props.history.push(address + "/" + mytype + "/" + mycases);
    }


    handleClickS(address, myid) {
        this.props.history.push(
            address,
            {
                id: myid,
            });;
    }

    keydown = (e) => {
        if (e.keyCode === 13) {
            var context = document.getElementById("search").value;
            this.handleClickS("/search", context);
        }
    }

    //====拉下菜单
    memudown(id) {
        switch (id) {
            case "product":
                document.getElementById("topbar-" + id).style.height = 360 / 14.4 + "vw";
                break;
            case "programme":
                document.getElementById("topbar-" + id).style.height = 400 / 14.4 + "vw";
                break;
            case "example":
                document.getElementById("topbar-" + id).style.height = 260 / 14.4 + "vw";
                break;
            case "cloud":
                document.getElementById("topbar-" + id).style.height = 320 / 14.4 + "vw";
                break;
            case "service":
                document.getElementById("topbar-" + id).style.height = 260 / 14.4 + "vw";
                break;
            case "about":
                document.getElementById("topbar-" + id).style.height = 360 / 14.4 + "vw";
                break;
            default:
                document.getElementById("topbar-" + id).style.height = 400 / 14.4 + "vw";
        }
        document.getElementById("topbar-icon-" + id).style.transform = "rotate(180deg)";
    }

    menuup(id) {
        document.getElementById("topbar-" + id).style.transitionDuration = '0s';

        document.getElementById("topbar-" + id).style.height = "0vw";
        setTimeout(function () {
            document.getElementById("topbar-" + id).style.transitionDuration = '0.3s';
        }, 300)

        document.getElementById("topbar-icon-" + id).style.transform = "rotate(0deg)";
    }

    render() {
        return (
            <div className="common">
                <div className="topbar-head">
                    <div className="head-box">
                        <div className="head">
                            <Link to="/"> <div className="head-logo"></div></Link>
                            <div className="interval-col-200" />
                            <div className="head-text" onMouseEnter={() => this.memudown("product")} onMouseLeave={() => this.menuup("product")} >
                                <div className="head-text-title">产品</div>
                                <div className="interval-col-10" />
                                <div className="head-text-icon" id="topbar-icon-product"></div>
                            </div>
                            <div className="head-text" onMouseEnter={() => this.memudown("programme")} onMouseLeave={() => this.menuup("programme")} >
                                <div className="head-text-title">方案</div>
                                <div className="interval-col-10" />
                                <div className="head-text-icon" id="topbar-icon-programme"></div>
                            </div>
                            <div className="head-text" onMouseEnter={() => this.memudown("example")} onMouseLeave={() => this.menuup("example")} >
                                <div className="head-text-title">案例</div>
                                <div className="interval-col-10" />
                                <div className="head-text-icon" id="topbar-icon-example"></div>
                            </div>
                            {/* <div className="head-text" onMouseEnter={() => this.memudown("cloud")} onMouseLeave={() => this.menuup("cloud")} >
                                <div className="head-text-title">云服务</div>
                                <div className="interval-col-10" />
                                <div className="head-text-icon" id="topbar-icon-cloud"></div>
                            </div> */}
                            {/* <div className="head-text" onMouseEnter={() => this.memudown("service")} onMouseLeave={() => this.menuup("service")} >
                                <div className="head-text-title">新云汇</div>
                                <div className="interval-col-10" />
                                <div className="head-text-icon" id="topbar-icon-service"></div>
                            </div> */}
                            <div className="head-text" onMouseEnter={() => this.memudown("about")} onMouseLeave={() => this.menuup("about")}  >
                                <div className="head-text-title">关于</div>
                                <div className="interval-col-10" />
                                <div className="head-text-icon" id="topbar-icon-about"></div>
                            </div>
                            {/* <div className="interval-col-40" />
                            <div className="head-search">
                                <div className="head-search-img"></div>
                                <input id="search" className="head-search-input" type="text" onKeyDown={(e) => this.keydown(e)} placeholder="搜索"></input>
                            </div> */}
                        </div>
                    </div>
                    {/*下拉框部分*/}
                    <Product mouseEnter={() => { this.memudown("product") }} mouseOut={() => { this.menuup("product") }} />
                    <Programme mouseEnter={() => { this.memudown("programme") }} mouseOut={() => { this.menuup("programme") }} />
                    <Example caseclick={(a, e, f) => this.handleCaseClick.bind(this, a, e, f)} mouseEnter={() => { this.memudown("example") }} mouseOut={() => { this.menuup("example") }} />
                    <Cloud mouseEnter={() => { this.memudown("cloud") }} mouseOut={() => { this.menuup("cloud") }} />
                    <Service mouseEnter={() => { this.memudown("service") }} mouseOut={() => { this.menuup("service") }} />
                    <About click={(e, f) => this.handleClick.bind(this, f, e)} mouseEnter={() => { this.memudown("about") }} mouseOut={() => { this.menuup("about") }} />
                </div>
            </div>
        );
    }
}

export default withRouter(Head);