/*
 * @Author: your name
 * @Date: 2019-12-30 14:48:51
 * @LastEditTime: 2022-02-15 15:25:14
 * @LastEditors: qxy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\CompanyIntroduction\development-history\DevelopmentHistory.js
 */
import "../../../global.js";
import React from "react";
//import './index.css';
import "./development-history.less";

//引入prefeb模块
import Bottom from "../../../common-components/BottomBar";
import Head from "../../../common-components/TopBar/TopBar";
import Guide from "../../../common-components/guide/Guide";

let developmentVideo = global.config.url + "/NewGrand/media/newgrand20180913.da890242.mp4";

//第一个模块
//网页第一个模块
class FirstPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false, //默认不选择
    };
  }
  playvideo(isture) {
    this.setState({
      isShow: isture,
    });
  }

  render() {
    const isShow = this.state.isShow;
    let myvideo;
    if (isShow == true) {
      myvideo = (
        <div className="first-page-vedio-box">
          <div className="first-page-vedio-bd" onClick={() => this.playvideo(false)}></div>
          <div className="first-page-vedio-margin">
            <div className="first-page-video-exit" onClick={() => this.playvideo(false)}>
              ×
            </div>
          </div>
          <div className="first-page-vedio">
            <video autoPlay="autoplay" loop="loop">
              <source src="movie.ogg" type="video/ogg" />
              <source src={global.config.url + "/newgrand/picture/img/bannerbg2.mp4"} type="video/mp4" />
              <source src="movie.webm" type="video/webm" />
              <object data="movie.mp4" width="100%" height="100%">
                <embed width="100%" height="100%" src="movie.swf" />>
              </object>
            </video>
          </div>
        </div>
      );
    } else {
      myvideo = <div></div>;
    }

    return (
      <div className="first-page">
        <video autoPlay="autoplay" loop="loop">
          <source src="movie.ogg" type="video/ogg" />
          <source src={developmentVideo} type="video/mp4" />
          <source src="movie.webm" type="video/webm" />
          <object data="movie.mp4" width="100%" height="100%">
            <embed width="100%" height="100%" src="movie.swf" />>
          </object>
        </video>
        {/*视频播放 
                {myvideo}
                <div className="first-page-show">
                    <div className="first-page-show-logo"></div>
                    <div className="first-page-show-icon" onClick={() => this.playvideo(true)}></div>
                </div>
                */}
      </div>
    );
  }
}

//网页第二个模块
class SecondPage extends React.Component {
  render() {
    return (
      <div className="second-page">
        {/* <div className="second-page-describe">
                    新中大以“创新、中和、大成、创业、执行、责任”为企业价值观，以“解决问题、创造价值”为服务理念，以“让天下没有难管的项目”为使命，力争成为互联网时代全球领先的项目管理信息化整体解决方案供应商！
                </div> */}
        <div className="second-page-logo"></div>
      </div>
    );
  }
}

//定义全局数组存储年份
const yearslist = [2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993];
//定义全局数组每年的具体内容
const textlist = [
  /*2021*/ "1月，新中大入选教育部产学合作协同育人项目。<br/>6月，新中大举办“P20中国建造（2021）管理创新峰会”。<br/>6月，新中大获评 “2020-2021年度中国区人力资源管理领先企业50强”。<br/>7月，新中大上榜2021信创产业分类排行榜。<br/>8月，新中大i8工程项目管理平台签约碧桂园腾越建筑。<br/>8月，新中大荣获“2020-2021年度工业互联网产业最具影响力企业”奖项。<br/>12月，新中大钱潮平台获评“2021数字建造领域国产自主可控优秀技术平台”",
  /*2020*/ "2月，疫情期间新中大免费向中国中小微工程建筑企业提供云端项目管理产品mi8使用权。<br/>5月，新中大研究院举办“新基建 新机遇 新课题”线上公益论坛。<br/>7月，云南建投集团“新中大项目管理系统”实施启动会顺利召开。<br/>" +
  "7月，新中大荣登2020数字基建百强。<br/>8月，CCID报告数据显示新中大占据建筑特级企业项目管理软件34%市场份额、税务管理软件40%市场份额。<br/>11月，新中大举办“P20中国建造（2020）管理峰会”，新中大钱潮技术中台、mi8工程项目管理云平台正式发布。",
  /*2019*/ "1月，新中大“智慧建造解决方案”荣获“2018年中国软件行业优秀解决方案”奖项。<br/>3月，上海建工集团股份有限公司“新中大项目管理平台”实施启动会在上海建工集团总部召开。" +
  "<br/>6月，新中大mi8工程项目管理云平台正式上市。<br/>8月，新中大发布了基于新中大智慧建造4.0整体架构的数据远传终端新型产品——工地芯。<br/>8月，新中大研究院正式成立。<br>8月，新中大云服务平台正式上线。<br/>12月，新中大获评“2019浙江极客非凡雇主”称号。",
  /*2018*/ "1月，2017中国信息化和软件服务业年度风云榜发布，新中大荣获企业、人物、产品、服务四项大奖。<br/>3月，“杭州新中大软件股份有限公司”正式更名为“杭州新中大企业管理技术有限公司”。<br/>" +
  "5月，新中大在“新常态下建筑企业管理创新研讨会”上正式提出“智慧建造4.0”理念。<br/>8月，新中大i8工程项目管理平台获评“2018中国软件行业工程建设管理领域最佳产品奖”。<br/>10月，新中大荣获“2018最具投资发展潜力软件企业”称号。<br/>11月，新中大入选“2018年浙江名牌产品”。",
  /*2017*/ "2月22日，成功登陆“新三板”，证券简称：新中大，证券代码：870650。<br/>6月，顺利通过美国软件工程学会SEI（Software Engineering Institute）颁发的CMMI5最高权威认证。<br/>11月23日，新中大发布了全新战略产品i8工程项目管理平台。",
  /*2016*/ "6月，与鲁班软件、华政形成战略联盟，开启工程建筑行业信息化新纪元。",
  /*2015*/ `1月20日，新中大深耕建筑业，与浙江大学、卡迪夫大学战略合作打造产学研一体化。<br>9月8日，总部迁往中国（杭州）智慧信息产业园。新中大开展近百场建筑业“营改增”专项讲座持续引领建筑行业信息化。`,
  /*2014*/ "10月，新中大与浙江大学管理学院签定战略合作协议。",
  /*2013*/ "3月29日，中国建筑业协会五届四次理事会暨五届五次常务理事会在京召开，新中大在此次会议上入选中国建筑业协会第五届理事会理事单位。<br/>8月20日，“橙色梦想 你我点亮”新中大二十周年庆典活动在浙江省人民大会堂隆重举行。同期，由新中大主办的“第五届工程行业管理创新与信息化战略总裁沙龙”、“政府理财信息化应用研讨会”、“企业转型升级与信息化战略”研讨会在杭州隆重召开。",
  /*2012*/ `3月，由新中大主办的第四届建筑企业管理创新与信息化战略总裁沙龙暨新中大i6工程版V12.0软件发布会在素有“东方夏威夷”之称的海南三亚隆重揭幕。<br/>7月，赛迪顾问股份有限公司出版中国工程建设项目管理软件市场白皮书，新中大软件以强大的综合优势。新中大依靠一体化的产品、完善的销售网络、众多的成功案例 和人才优势，在中国工程建设行业项目管理软件市场表现突出。新中大在国内大中型施工企业项目管理软件市场占比为后五位厂商（PKPM、易建、用友、广联达梦龙、金蝶）之和。`,
  /*2011*/ "1月，新中大“URP软件i6系统工程版”获批2011杭州市高技术产业化项目。<br/>4月，新中大作为首批通过《财经信息技术会计核算软件数据接口》(GB/ T 24589-2010)国家标准的七家企业之一，赴京参加国家标准实施推进成果新闻发布会。",
  /*2010*/ "4月，新中大联合恒生电子股份有限公司、浙江大学、信雅达股份有限公司、杭州国家软件产业基地有限公司等单位共同建设的国家“核心电子器件、高端通用芯片及基础软件产品 ”科技重大专项2009年课题“面向金融领域的应用平台研制及产业化”项目获批立项。<br/>5月，由江苏省住房和城乡建设厅主办，新中大独家承办的江苏省第二届建筑企业信息化建设论坛在维景国际大酒店隆重召开。<br/>7月，浙江省建筑业科学发展论坛在之江饭店胜利召开，此次活动由浙江省建筑业行业协会主办，新中大承办! <br/>9月，新中大“基于SOA的农村集体三资管理系统研制”入选2010杭州市信息服务业专项。 <br/>11月，“基于柔性化平台SUP的制造执行系统i6MES研发及应用”入选2010浙江省 信息服务业专项。",
  /*2009*/ "1月，新中大联合浙江大学、哈尔滨工业大学、浙江正泰电器股份有限公司等单位共同建设的国家高技术研究发展计划（863计划）项目“面向块状经济区域集群式供应链的服务支 持技术及其平台研发与应用”项目获批立项，正式投入研发。",
  /*2008*/ "确立了“行业管理专家”和“项目管理专家”的企业定位和发展方向。<br/>连续中标苏州二建、南京建工、南通六建、龙信集团、中铁十九局、葛洲坝集团等众多特、一级施工总承包企业，市场占有率一度达到60%。",
  /*2007*/ "7月，石钟韶总裁的第三本新书《抢位互联网，URP如何削平商业世界》在北京京广中心隆重发行，在互联网彻底变革商业世界的趋势下，石钟韶总裁的 “URP+互联网”全新思想 也将进入实践的新阶段。<br/>7月，新中大成功举办了2007先进管理学堂活动——“南北商帮，京城论剑”京商、浙商创新论坛，活动得到了中华全国工商业联合会经济部、北京市工商业联合会经济技术委员会 、浙江省工商业联合会经济法律委员会的支持与指导，来自北京、浙江两地的600余位企业家共同参加了这一商业思想碰撞的盛会。",
  /*2006*/ "7月、10月，新中大公司分别在广州、南京两地召开两次先进管理学堂活动——“两江对话”、“江浙论剑”，超过1000位浙商、粤商、苏商齐聚一堂，共话商业模式创新。<br/>12月，新中大在北京新世纪饭店举办题为“集团管理，URP领航”的新中大集团管理解决方案交流会，国内各主流IT类媒体以及数十家北京当地新中大软件的集团用户代表参与了本次会议。会上，新中大发布了包括集团决策、集团财务、集团报表、集团HR、集团办公五大套件的集团管理信息化解决方案。",
  /*2005*/ `2月，在赛迪集团与信息产业部电子信息中心联合主办的2005全国IT市场年度评选中，新中大公司喜获“2004-2005中国IT市场年度成就奖”。<br/>5月，新中大公司首批通过《信息技术 会计核算软件数据接口》(GB/T19581-2004)国家标准认证。`,
  /*2004*/ "2月，信息产业部电子信息产业发展基金管理办公室正式发文认定新中大通过2003年度电子信息产业发展基金项目。<br/>6月，新中大先后获得“2004年中国电脑商500强之供应商100强”、“2004年中国软件产业最大规模前100家企业”、“2004年中国独立软件开发企业最大规模前30家企业”等称号。",
  /*2003*/ "1月，经国家发展计划委员会、信息产业部、对外贸易经济合作部、国家税务总局审核， 新中大公司被认定为“2002年度国家规划布局内的重点软件企业”。<br/>4月，新中大《终结ERP》发布，引发ERP行业大讨论。 ",
  /*2002*/ "7月，新中大被认定为国家火炬计划软件产业基地骨干企业，同时新中大国际财务管理软件被列入国家级火炬计划项目。<br/>10月，新中大URP软件i6系统通过科技部首批ERP产品评测。",
  /*2001*/ "2月22日，为适应全球协同管理的需要，发布新中大互动管理URP软件i6系统。<br/>6月5日，新中大财务管理软件NGPower5.08正式发布。",
  /*2000*/ "3月21日，新中大推出中国首款支持国际会计准则的财务软件Intfi，软件支持多国语言、多币种等国际化元素。<br/>4月，根据国家财政部财政改革的要求，新中大正式向业界推出了新中大会计委派软件。<br/>" +
  "2000年，公司收购杭州天合信息技术有限公司，进军工程行业市场。<br/>12月29日，成功完成股份制改造，杭州新中大软件股份有限公司正式成立。",
  /*1999*/ "2月，公司总部迁往杭州水晶大厦，办公环境得到一定改善，同时在组织架构、人才团队、技术体系等方面得到全面发展。<br/>3月，与微软（中国）公司正式签署战略合作协议，联合向业界推出基于Windows环境和MS SQL Server 7.0数据库的财务管理解决方案。<br/>" +
  "5月，新中大与中国轻纺城集团股份有限公司正式签定战略合作协议，浙江省规模最大的软件企业诞生。<br/>12月，成功签约中国移动通信集团，软件成交金额达千万元人民币，成为当时全国财务软件业一次性定单最大的软件项目。",
  /*1998*/ "4月，新中大公司被中国软件行业协会接收为会员单位，并被推选为理事单位。<br/>11月，新中大财务软件V3.1版通过财政部专家评审，同时现金流量表率先通过评审。",
  /*1997*/ "8月，MRPII事业部成立，开始开发贯穿全新管理思想、具有国内先进水平的PowerERP软件。<br/>11月，推出金融行业专版财务软件，并正式成立金融软件事业部。<br/>11月，新中大财务软件V3.0版上市。",
  /*1996*/ "3月，新中大财务软件V1.0版通过浙江省财政厅专家评审。<br/>10月，新中大财务软件2.0版成功面市。",
  /*1995*/ "9月，新中大财务软件V1.0版诞生.",
  /*1994*/ "6月，新中大开始开发财务及企业管理软件。",
  /*1993*/ "8月20日，新中大公司成立于西子湖畔。",
];
//定义全局数组存储图片的url
const imglist = [
  /*2021*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2021.jpg",
  /*2020*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2020.png",
  /*2019*/ global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%8F%AF%E8%A7%86%E5%8C%96%E5%B7%A5%E5%9C%B0%E7%AE%A1%E7%90%86/01.jpg",
  /*2018*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2018.jpg",
  /*2017*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2017.jpg",
  /*2016*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2016.jpg",
  /*2015*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2015.jpg",
  /*2014*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2014.jpg",
  /*2013*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2013.png",
  /*2012*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2012.png",
  /*2011*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2011.png",
  /*2010*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2010.png",
  /*2009*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2009.png",
  /*2008*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2008.jpg",
  /*2007*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2007.jpg",
  /*2006*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2006.jpg",
  /*2005*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2005.jpg",
  /*2004*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2004.jpg",
  /*2003*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2003.jpg",
  /*2002*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2002.jpg",
  /*2001*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2001.png",
  /*2000*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/2000.jpg",
  /*1999*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/1999.jpg",
  /*1998*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/1998.jpg",
  /*1997*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/1997.jpg",
  /*1996*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/1996.jpg",
  /*1995*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/1995.png",
  /*1994*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/1994.jpg",
  /*1993*/ global.config.url + "/newgrand/picture/img/%E4%B8%80%E5%B9%B4%E4%B8%80%E5%9B%BE/1993.png",
];
//网页第三模块
class ThirdPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: 0, //进度条初始位置
      prepage: 2020, // 定位上次改变的位置
    };
  }

  //控制进度条右移
  letRight() {
    let location = this.state.location;
    location = location - 100 / 14.4;
    console.log(location);
    if (location >= -480 / 14.4) {
      this.setState({
        location: location,
      });
    }
  }
  //传入页面年份
  changePage(page) {
    if (page <= 2021 && page >= 2014) {
      document.getElementById("img-3").style.display = "inline";
      // if (page == 1994 || (page >= 2009 && page <= 2013)) {
      //     document.getElementById("img-3").style.display = "none";
      // }
      document.getElementById("year-3").innerHTML = yearslist[yearslist[0] - page] + "年";
      document.getElementById("text-3").innerHTML = textlist[yearslist[0] - page];
      document.getElementById("img-3").src = imglist[yearslist[0] - page];
      this.ReYearStyle(this.state.prepage);
      this.changeYearStyle(page);
      this.setState({
        prepage: page,
      });
      if (page <= 2021 && page >= 2018) {
        this.setState({
          location: 0,
        });
      } else {
        this.setState({
          location: -440 / 14.4,
        });
      }
    }
  }

  //改变的样式
  changeYearStyle(page) {
    document.getElementById("show-year-3" + page).style.width = 102 / 14.4 + "vw";
    document.getElementById("show-year-3" + page).style.fontSize = 46 / 14.4 + "vw";
    document.getElementById("show-year-3" + page).style.color = "rgba(243,152,0,1)";
    document.getElementById("show-year-3" + page).style.marginLeft = 23.5 / 14.4 + "vw";
    document.getElementById("show-year-3" + page).style.marginRight = 23.5 / 14.4 + "vw";

    document.getElementById("show-point-3" + page).style.width = 20 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.height = 20 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.marginRight = 3 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.marginLeft = 3 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.borderRadius = 10 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.background = "rgba(243, 152, 0, 1)";
    document.getElementById("show-point-3" + page).style.boxShadow = "0px 2vw/@w 2vw/@w 0px rgba(243, 137, 0, 1)";
  }

  //恢复样式
  ReYearStyle(page) {
    document.getElementById("show-year-3" + page).style.width = 54 / 14.4 + "vw";
    document.getElementById("show-year-3" + page).style.fontSize = 24 / 14.4 + "vw";
    document.getElementById("show-year-3" + page).style.color = "rgba(0, 0, 0, 1)";
    document.getElementById("show-year-3" + page).style.marginLeft = 47.5 / 14.4 + "vw";
    document.getElementById("show-year-3" + page).style.marginRight = 47.5 / 14.4 + "vw";

    document.getElementById("show-point-3" + page).style.width = 12 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.height = 12 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.marginRight = 7 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.marginLeft = 7 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.borderRadius = 6 / 14.4 + "vw";
    document.getElementById("show-point-3" + page).style.background = "rgba(143, 145, 146, 1)";
    document.getElementById("show-point-3" + page).style.boxShadow = "";
  }

  //控制进度条左移
  letLeft() {
    let location = this.state.location;
    location = location + 100 / 14.4;
    if (location <= 0 / 14.4) {
      this.setState({
        location: location,
      });
    } else {
      this.setState({
        location: 0,
      });
    }
  }

  componentDidMount() {
    this.changePage(2020);
  }

  render() {
    let yearList = [];
    let bottomList = [];
    yearList.push(<div key={"head"} className="intervals-col-615"></div>);
    bottomList.push(
      <div key={"head1"} className="bottom-line">
        {" "}
      </div>
    );
    for (let i = 2021; i >= 2014; i--) {
      yearList.push(
        <div key={i} id={"show-year-3" + i} className="show-time-year" onClick={() => this.changePage(i)}>
          {i}
        </div>
      );
      bottomList.push(
        <div key={i + "a"} id={"show-point-3" + i} className="bottom-point">
          {" "}
        </div>
      );
      bottomList.push(
        <div key={i + "b"} className="bottom-line">
          {" "}
        </div>
      );
    }
    for (let i = 2013; i >= 1996; i--) {
      yearList.push(<div key={i} id={"show-year-3" + i} className="show-time-year" onClick={() => this.changePage(i)}></div>);
      bottomList.push(
        <div key={i + "a"} id={"show-point-3" + i} className="bottom-point">
          {" "}
        </div>
      );
      bottomList.push(
        <div key={i + "b"} className="bottom-line">
          {" "}
        </div>
      );
    }

    let List = (
      <div className="show-time">
        <div className="time-line-top" id="top" style={{ transform: "translateX(" + this.state.location + "vw)" }}>
          {yearList}
        </div>
        <div className="time-line-bottom" id="bottom" style={{ transform: "translateX(" + this.state.location + "vw)" }}>
          {bottomList}
        </div>
      </div>
    );
    return (
      <div className="third-page">
        <div className="show-years">2014至今</div>
        <div className="show-describe">行业平台厂商阶段</div>
        <div className="show-time-box">
          <div className="show-time-left" onClick={() => this.letLeft()}></div>
          <div className="show-time-right" onClick={() => this.letRight()}></div>
          {List}
        </div>
        <div className="show-each">
          <div className="left">
            <div className="show-each-year" id="year-3">
              2015年
            </div>
            <div className="show-each-text" id="text-3">
              1月20日，新中大深耕建筑业，与浙江大学、卡迪夫大学战略合作打造产学研一体化。
              <br />
              9月8日，总部迁往中国（杭州）智慧信息产业园。新中大开展近百场建筑业“营改增”专项讲座持续引领建筑行业信息化。
            </div>
            <div className="interval-80" />
          </div>
          <div className="right">
            <div className="show-each-img">
              <img id="img-3" src={global.config.url + "/newgrand/picture/img/%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D/%E5%8F%91%E5%B1%95%E5%8E%86%E7%A8%8B/Event-1.png"} />
            </div>
            <div className="interval-80" />
          </div>
        </div>
      </div>
    );
  }
}

//网页第四个模块
class FourthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: -600 / 14.4, //进度条初始位置
      prepage: 2013, // 定位上次改变的位置
    };
  }

  //控制进度条右移
  letRight() {
    let location = this.state.location;
    location = location - 100 / 14.4;
    if (location >= -1480 / 14.4) {
      this.setState({
        location: location,
      });
    } else {
      this.setState({
        location: -1480 / 14.4,
      });
    }
  }
  //传入页面年份
  changePage(page) {
    if (page <= 2013 && page >= 2004) {
      document.getElementById("img-4").style.display = "inline";
      // if (page == 1994 || (page >= 2009 && page <= 2013)) {
      //     document.getElementById("img-4").style.display = "none";
      // }
      document.getElementById("year-4").innerHTML = yearslist[yearslist[0] - page] + "年";
      document.getElementById("text-4").innerHTML = textlist[yearslist[0] - page];
      document.getElementById("img-4").src = imglist[yearslist[0] - page];
      this.ReYearStyle(this.state.prepage);
      this.changeYearStyle(page);
      this.setState({
        prepage: page,
      });
      if (page === 2013 || page === 2012) {
        this.setState({
          location: -750 / 14.4,
        });
      } else if (page >= 2004 && page <= 2006) {
        this.setState({
          location: -1480 / 14.4,
        });
      } else {
        this.setState({
          location: (-(2016 - page) * 150) / 14.4,
        });
      }
    }
  }

  //改变的样式
  changeYearStyle(page) {
    document.getElementById("show-year-4" + page).style.width = 102 / 14.4 + "vw";
    document.getElementById("show-year-4" + page).style.fontSize = 46 / 14.4 + "vw";
    document.getElementById("show-year-4" + page).style.color = "rgba(243,152,0,1)";
    document.getElementById("show-year-4" + page).style.marginLeft = 23.5 / 14.4 + "vw";
    document.getElementById("show-year-4" + page).style.marginRight = 23.5 / 14.4 + "vw";

    document.getElementById("show-point-4" + page).style.width = 20 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.height = 20 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.marginRight = 3 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.marginLeft = 3 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.borderRadius = 10 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.background = "rgba(243, 152, 0, 1)";
    document.getElementById("show-point-4" + page).style.boxShadow = "0px 2vw/@w 2vw/@w 0px rgba(243, 137, 0, 1)";
  }

  //恢复样式
  ReYearStyle(page) {
    document.getElementById("show-year-4" + page).style.width = 54 / 14.4 + "vw";
    document.getElementById("show-year-4" + page).style.fontSize = 24 / 14.4 + "vw";
    document.getElementById("show-year-4" + page).style.color = "rgba(0, 0, 0, 1)";
    document.getElementById("show-year-4" + page).style.marginLeft = 47.5 / 14.4 + "vw";
    document.getElementById("show-year-4" + page).style.marginRight = 47.5 / 14.4 + "vw";

    document.getElementById("show-point-4" + page).style.width = 12 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.height = 12 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.marginRight = 7 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.marginLeft = 7 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.borderRadius = 6 / 14.4 + "vw";
    document.getElementById("show-point-4" + page).style.background = "rgba(143, 145, 146, 1)";
    document.getElementById("show-point-4" + page).style.boxShadow = "";
  }

  //控制进度条左移
  letLeft() {
    let location = this.state.location;
    location = location + 100 / 14.4;
    if (location <= -750 / 14.4) {
      this.setState({
        location: location,
      });
    } else {
      this.setState({
        location: -750 / 14.4,
      });
    }
  }

  componentDidMount() {
    this.changePage(2013);
  }

  render() {
    let yearList = [];
    let bottomList = [];
    yearList.push(<div key={"head"} className="intervals-col-615"></div>);
    bottomList.push(
      <div key={"head1"} className="bottom-line">
        {" "}
      </div>
    );
    for (let i = 2018; i >= 1996; i--) {
      if (i > 2013 || i < 2004) {
        yearList.push(<div key={i} id={"show-year-4" + i} className="show-time-year" onClick={() => this.changePage(i)}></div>);
      } else {
        yearList.push(
          <div key={i} id={"show-year-4" + i} className="show-time-year" onClick={() => this.changePage(i)}>
            {i}
          </div>
        );
      }
      bottomList.push(
        <div key={i + "a"} id={"show-point-4" + i} className="bottom-point">
          {" "}
        </div>
      );
      bottomList.push(
        <div key={i + "b"} className="bottom-line">
          {" "}
        </div>
      );
    }

    let List = (
      <div className="show-time">
        <div className="time-line-top" id="top" style={{ transform: "translateX(" + this.state.location + "vw)" }}>
          {yearList}
        </div>
        <div className="time-line-bottom" id="bottom" style={{ transform: "translateX(" + this.state.location + "vw)" }}>
          {bottomList}
        </div>
      </div>
    );
    return (
      <div className="fourth-page">
        <div className="show-years" style={{ color: "rgba(255, 255, 255, 1)" }}>
          2004-2013
        </div>
        <div className="show-describe" style={{ color: "rgba(255, 255, 255, 1)" }}>
          行业化转型阶段
        </div>
        <div className="show-time-box" style={{ background: "rgba(255,255,255,0.06)", border: "0", borderRadius: "10px" }}>
          <div className="show-time-left" onClick={() => this.letLeft()}></div>
          <div className="show-time-right" onClick={() => this.letRight()}></div>
          {List}
        </div>
        <div className="show-each">
          <div className="left">
            <div className="show-each-year" id="year-4" style={{ color: "rgba(255, 255, 255, 1)" }}>
              2013年
            </div>
            <div className="show-each-text" id="text-4" style={{ color: "rgba(255, 255, 255, 1)" }}>
              4月28日，新中大助力中交上海航道局成为首批成功通过住建部一级升特的唯一一家企业。
              <br />
              7月，编写《工程项目管理信息化》书籍，制定工程项目管理信息化标准。
            </div>
            <div className="interval-80" />
          </div>
          <div className="right">
            <div className="show-each-img">
              <img id="img-4" src={global.config.url + "/newgrand/picture/img/%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D/%E5%8F%91%E5%B1%95%E5%8E%86%E7%A8%8B/Event-2.png"} />
            </div>
            <div className="interval-80" />
          </div>
        </div>
      </div>
    );
  }
}

//网页第五个模块
class FifthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: -2540 / 14.4, //进度条初始位置
      prepage: 1999, // 定位上次改变的位置
    };
  }

  //控制进度条右移
  letRight() {
    let location = this.state.location;
    location = location - 100 / 14.4;
    if (location >= -3151 / 14.4) {
      this.setState({
        location: location,
      });
    }
  }
  //传入页面年份
  changePage(page) {
    if (page <= 2003 && page >= 1993) {
      document.getElementById("img-5").style.display = "inline";
      if (page === 1994 || (page >= 2009 && page <= 2013)) {
        document.getElementById("img-5").style.display = "none";
      }
      document.getElementById("year-5").innerHTML = yearslist[yearslist[0] - page] + "年";
      document.getElementById("text-5").innerHTML = textlist[yearslist[0] - page];
      document.getElementById("img-5").src = imglist[yearslist[0] - page];
      this.ReYearStyle(this.state.prepage);
      this.changeYearStyle(page);
      this.setState({
        prepage: page,
      });
      if (page === 2003 || page === 2002) {
        this.setState({
          location: -2250 / 14.4,
        });
      } else if (page <= 1995 && page >= 1993) {
        this.setState({
          location: -3150 / 14.4,
        });
      } else {
        this.setState({
          location: (-(2016 - page) * 150) / 14.4,
        });
      }
    }
  }

  //改变的样式
  changeYearStyle(page) {
    document.getElementById("show-year-5" + page).style.width = 102 / 14.4 + "vw";
    document.getElementById("show-year-5" + page).style.fontSize = 46 / 14.4 + "vw";
    document.getElementById("show-year-5" + page).style.color = "rgba(243,152,0,1)";
    document.getElementById("show-year-5" + page).style.marginLeft = 23.5 / 14.4 + "vw";
    document.getElementById("show-year-5" + page).style.marginRight = 23.5 / 14.4 + "vw";

    document.getElementById("show-point-5" + page).style.width = 20 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.height = 20 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.marginRight = 3 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.marginLeft = 3 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.borderRadius = 10 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.background = "rgba(243, 152, 0, 1)";
    document.getElementById("show-point-5" + page).style.boxShadow = "0px 2vw/@w 2vw/@w 0px rgba(243, 137, 0, 1)";
  }

  //恢复样式
  ReYearStyle(page) {
    document.getElementById("show-year-5" + page).style.width = 54 / 14.4 + "vw";
    document.getElementById("show-year-5" + page).style.fontSize = 24 / 14.4 + "vw";
    document.getElementById("show-year-5" + page).style.color = "rgba(0, 0, 0, 1)";
    document.getElementById("show-year-5" + page).style.marginLeft = 47.5 / 14.4 + "vw";
    document.getElementById("show-year-5" + page).style.marginRight = 47.5 / 14.4 + "vw";

    document.getElementById("show-point-5" + page).style.width = 12 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.height = 12 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.marginRight = 7 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.marginLeft = 7 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.borderRadius = 6 / 14.4 + "vw";
    document.getElementById("show-point-5" + page).style.background = "rgba(143, 145, 146, 1)";
    document.getElementById("show-point-5" + page).style.boxShadow = "";
  }

  //控制进度条左移
  letLeft() {
    let location = this.state.location;
    location = location + 100 / 14.4;
    if (location <= -2250 / 14.4) {
      this.setState({
        location: location,
      });
    } else {
      this.setState({
        location: -2250 / 14.4,
      });
    }
  }

  componentDidMount() {
    this.changePage(2003);
  }

  render() {
    let yearList = [];
    let bottomList = [];
    yearList.push(<div key={"head"} className="intervals-col-615"></div>);
    bottomList.push(
      <div key={"head1"} className="bottom-line">
        {" "}
      </div>
    );
    for (let i = 2018; i >= 1993; i--) {
      if (i > 2003) {
        yearList.push(<div key={i} id={"show-year-5" + i} className="show-time-year" onClick={() => this.changePage(i)}></div>);
      } else {
        yearList.push(
          <div key={i} id={"show-year-5" + i} className="show-time-year" onClick={() => this.changePage(i)}>
            {i}
          </div>
        );
      }
      bottomList.push(
        <div key={i + "a"} id={"show-point-5" + i} className="bottom-point">
          {" "}
        </div>
      );
      bottomList.push(
        <div key={i + "b"} className="bottom-line">
          {" "}
        </div>
      );
    }

    let List = (
      <div className="show-time">
        <div className="time-line-top" id="top" style={{ transform: "translateX(" + this.state.location + "vw)" }}>
          {yearList}
        </div>
        <div className="time-line-bottom" id="bottom" style={{ transform: "translateX(" + this.state.location + "vw)" }}>
          {bottomList}
        </div>
      </div>
    );
    return (
      <div className="fifth-page">
        <div className="show-years">1993-2003</div>
        <div className="show-describe">通用软件厂商阶段</div>
        <div className="show-time-box">
          <div className="show-time-left" onClick={() => this.letLeft()}></div>
          <div className="show-time-right" onClick={() => this.letRight()}></div>
          {List}
        </div>
        <div className="show-each">
          <div className="left">
            <div className="show-each-year" id="year-5">
              1999年
            </div>
            <div className="show-each-text" id="text-5">
              2月，公司总部迁往杭州水晶大厦，办公环境得到一定改善，同时在组织架构、人才团队、技术体系等方面得到全面发展。
              <br />
              3月，与微软（中国）公司正式签署战略合作协议，联合向业界推出基于Windows环境和MS SQL Server 7.0数据库的财务管理解决方案。
              <br />
              5月，新中大与中国轻纺城集团股份有限公司正式签定战略合作协议，浙江省规模最大的软件企业诞生。
              <br />
              12月，成功签约中国移动通信集团，软件成交金额达千万元人民币，成为当时全国财务软件业一次性定单最大的软件项目。
            </div>
            <div className="interval-80" />
          </div>
          <div className="right">
            <div className="show-each-img">
              <img id="img-5" src={global.config.url + "/newgrand/picture/img/%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D/%E5%8F%91%E5%B1%95%E5%8E%86%E7%A8%8B/Event-3.png"} />
            </div>
            <div className="interval-80" />
          </div>
        </div>
      </div>
    );
  }
}

//网页主模块
class DevelopmentHistory extends React.Component {
  render() {
    return (
      <div>
        <Head />
        <div className="development-history">
          <FirstPage />
          <SecondPage />
          <ThirdPage />
          <FourthPage />
          <FifthPage />
        </div>
        <Bottom />
      </div>
    );
  }
}

export default DevelopmentHistory;
